import { HttpClient } from '@angular/common/http';
import { ValidatorFn, UntypedFormGroup, ValidationErrors, UntypedFormControl, Validators } from '@angular/forms';

export class RegisterPinForm {
	// Our form group
	formGroup: UntypedFormGroup = null;

	constructor(private http: HttpClient) {
		this.formGroup = new UntypedFormGroup({});
		this.formGroup.addControl(
			'pin',
			new UntypedFormControl('', {
				validators: [
					Validators.required,
					Validators.minLength(4),
					Validators.maxLength(10),
					Validators.pattern('^[0-9]*$'),
				],
				updateOn: 'blur'
			})
		);
		this.formGroup.addControl('confirmPin', new UntypedFormControl('', [Validators.required, this.pinEqualValidator]));
	}

	// Validator to ensure new pin === confirmation pin
	pinEqualValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
		if (
            this.formGroup 
            && this.formGroup.value
        ) {
			const pin = this.formGroup.value.pin;
			const confirmPin = control.value;
			return pin === confirmPin 
                ? null 
                : { pinsNotEqual: true };
		}
		return null;
	};
}
