import { HttpClient } from "@angular/common/http";
import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserRoleInfoModalComponent } from "app/administration/users/edit-user/user-role-info-modal/user-role-info-modal.component";
import { BusinessAreaEnum } from "app/core/enums/generated/BusinessAreaEnum";
import { PermissionRole } from "app/core/enums/generated/PermissionRole";
import { availableModules, SecurityService } from "app/core/security/security.service";
import { SystemMessage, SystemMessageService } from "app/core/system-message/system-message-service";
import { ModuleAccessVm } from "app/shared/generated/Administration/Models/Security/ModuleAccessVm";
import { UserModulesVm } from "app/shared/generated/Administration/Models/Security/UserModulesVm";
import { ToastrService } from "ngx-toastr";
import { debounceTime, Subscription } from "rxjs";
import { EditUserService } from "../edit-user.service";

@Component({
    selector: "pcg-user-module-access",
    templateUrl: "./user-module-access.component.html",
    styleUrls: ["./user-module-access.component.scss"],
    standalone: false
})
export class UserModuleAccessComponent implements OnInit, OnDestroy {
	route = inject(ActivatedRoute);
	router = inject(Router);
	ms = inject(SystemMessageService);
	httpClient = inject(HttpClient);
	security = inject(SecurityService);
	modalService = inject(NgbModal);
	editUserService = inject(EditUserService);
	toastr = inject(ToastrService);
	
	isSysAdmin = false;
	allModulesAssigned: boolean;
	businessAreaEnum = BusinessAreaEnum;

	formGroup = UserModulesVm.Form;
	userId: number;
	
	routeSubscription: Subscription;

	constructor(title: Title) { title.setTitle("User Module Access"); }

	ngOnInit() {
		this.isSysAdmin = this.security.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, SecurityService.setMinRole(PermissionRole.SystemAdmin)
		);

		// If navigating from 1 user edit to another, make sure to update signals
		this.routeSubscription = this.route.paramMap.subscribe(
			() => {
				this.userId = parseInt(this.route.snapshot.params["id"]);
				this.getModuleAccess();
			}
		);
	}

	ngOnDestroy = () => this.routeSubscription.unsubscribe();

	getModuleAccess = () => {
		this.editUserService.getModuleAccesses(this.userId).pipe(
			debounceTime(300) // Debounce to avoid frequent updates
		).subscribe(
			(r: UserModulesVm) => {
				this.editUserService.moduleAccessesSignal.set(r);
				this.updateForm();
			}
		);
	}

	canEditModuleAccess = (module: BusinessAreaEnum, role: PermissionRole) =>
		this.security.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.SystemAdmin ]
		) 
		|| (
			this.security.hasModuleAccess(
				[ module ]
				, [
					PermissionRole.UserAdmin
					, ...SecurityService.setMinRole(PermissionRole.Manager)
				]
			) 
			&& this.security.hasModuleAccess(
				[ module ]
				, SecurityService.setMinRole(role)
			)
		);

	updateForm() {
		this.formGroup.patchValue(this.editUserService.moduleAccessesSignal());
		this.getAvailableModules();

		// Update security and navigation when changing module access for self.
		if (this.userId === this.security.getUser().id) {
			var user = this.security.getUser();
			user.moduleAccess = this.editUserService.moduleAccessesSignal().moduleAccess;
			this.security.setSecurity(undefined, user);
		}
	}

	openRoleInfoModal(module: BusinessAreaEnum) {
		const modalRef = this.modalService.open(UserRoleInfoModalComponent, { size: "xl" });
		modalRef.componentInstance.module = module;
	}

	// Populate all modules that implement module access security.
	getAvailableModules() {
		const availableModulesToAdd = availableModules.filter((value) => 
			!this.editUserService.moduleAccessesSignal().moduleAccess.find((m) => m.module == value)
		);
	
		availableModulesToAdd.forEach((key) => this.addModuleAccess(key));
	
		// Sort alphabetically
		const updatedModuleAccesses = this.editUserService.moduleAccessesSignal().moduleAccess.sort((a, b) => {
			return a.displayName.toUpperCase() < b.displayName.toUpperCase()
				? -1
				: a.displayName.toUpperCase() > b.displayName.toUpperCase()
					? 1
					: 0;
		});
	
		this.editUserService.moduleAccessesSignal.update((current) => ({
			...current,
			moduleAccess: updatedModuleAccesses
		}));
	
		this.formGroup.patchValue(this.editUserService.moduleAccessesSignal());
	}
	
	addModuleAccess(module) {
		if (this.isSysAdmin) {
			this.editUserService.moduleAccessesSignal.update((current) => ({
				...current,
				moduleAccess: [
					Object.assign(new ModuleAccessVm(), {
						moduleAccessId: 0,
						userId: current.userId,
						module: Number(module),
						permissionRole: PermissionRole.NoAccess,
						displayName: this.getDisplayName(Number(module))
					}),
					...current.moduleAccess
				]
			}));
		}
	}

	getDisplayName(businessArea: BusinessAreaEnum) { return this.businessAreaEnum.toDisplay(businessArea); }

	updateModuleAccess = () =>
		this.editUserService.setModuleAccesses(this.formGroup).subscribe(
			(r: SystemMessage) => {
				if (!r.isSuccessful) {
					this.toastr.error(r.message, null, { positionClass: 'toast-top-right', timeOut: 2000});
				} else {
					this.toastr.success(r.message, null, { positionClass: 'toast-top-right', timeOut: 2000});
					this.editUserService.moduleAccessesSignal.set(r.model);
				}	
				this.updateForm();			
			}
		);

	createTicket() {
        this.httpClient.get(`api/HelpDesk/HelpDeskTicket/CreateTicket?isSysAdmin=${this.isSysAdmin}`)
            .subscribe((msg: SystemMessage) => {
                if (msg.isSuccessful) { this.router.navigateByUrl(`/support/tickets/edit/${msg.value}?request=moduleAccess`); } 
                else { this.ms.setSystemMessage(msg.message, 'error'); }
            }
		);
    }
}
