import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class UserEmailSettingsVm {
	userEmailSettingId: number;
	userId: number;
	inventoryIds?: number[];
	reconciliationEmails: number;
	reconciliationNotifications: number;
	reorderLowInventory: number;
	overrideReorderSettings: number;
	fulfillmentOverdue1: number;
	fulfillmentOverdue2: number;
	fulfillmentUserStatusChange: number;
	fulfillmentUserRxDeleted: number;
	fulfillmentOverdueFirstInventoryIds?: number[];
	fulfillmentOverdueSecondInventoryIds?: number[];
	metabolicFormulaSubmitted: number;
	metabolicFormulaComments: number;
	inventoryChange: number;
	herosPendingAgencyEmails: number;
	newCiInvoicesAvailable: number;
	newCiInvoicesAvailableNotifications: number;
	repackInvoiceEmail: number;
	newCiOrdersAvailable: number;
	ciOrderCommentEmail: number;
	ciOrderNotSubmitted: number;
	ciBatchControlRecord: number;
	ciEndOfWeekMetrics: number;
	releaseNotes: number;
	helpDeskTicketUpdate: boolean;
	userEmailBounced: number;
	newErrorReportSubmitted: number;
	baFiscalYearCreated: number;
	baNewBudgetCreated: number;
	budgetAllocationAdmin: number;
	organizationAccountRequestEmail: number;
	organizationAccountExpirationEmail: number;
	ticketUpdate: number;
	ticketCreated: number;
	ticketStatusChange: number;
	ticketCommentAdded: number;
	ticketAssigned: number;
	newSurveyAvailable: number;
	surveyCompletionReminder: number;
	overdueSurveyReminder: number;
	scheduleSiteVisitReminder: number;
	postSiteVisitReminder: number;
	sysInvOrderSubmitted: number;
	friendlyId?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'userEmailSettingId': new UntypedFormControl(0, [...(validators['userEmailSettingId'] ?? []), ...[Validators.required]]),
			'userId': new UntypedFormControl(0, [...(validators['userId'] ?? []), ...[Validators.required]]),
			'inventoryIds': new UntypedFormControl([], [...(validators['inventoryIds'] ?? []), ...[]]),
			'reconciliationEmails': new UntypedFormControl(0, [...(validators['reconciliationEmails'] ?? []), ...[Validators.required]]),
			'reconciliationNotifications': new UntypedFormControl(0, [...(validators['reconciliationNotifications'] ?? []), ...[Validators.required]]),
			'reorderLowInventory': new UntypedFormControl(0, [...(validators['reorderLowInventory'] ?? []), ...[Validators.required]]),
			'overrideReorderSettings': new UntypedFormControl(0, [...(validators['overrideReorderSettings'] ?? []), ...[Validators.required]]),
			'fulfillmentOverdue1': new UntypedFormControl(0, [...(validators['fulfillmentOverdue1'] ?? []), ...[Validators.required]]),
			'fulfillmentOverdue2': new UntypedFormControl(0, [...(validators['fulfillmentOverdue2'] ?? []), ...[Validators.required]]),
			'fulfillmentUserStatusChange': new UntypedFormControl(0, [...(validators['fulfillmentUserStatusChange'] ?? []), ...[Validators.required]]),
			'fulfillmentUserRxDeleted': new UntypedFormControl(0, [...(validators['fulfillmentUserRxDeleted'] ?? []), ...[Validators.required]]),
			'fulfillmentOverdueFirstInventoryIds': new UntypedFormControl([], [...(validators['fulfillmentOverdueFirstInventoryIds'] ?? []), ...[]]),
			'fulfillmentOverdueSecondInventoryIds': new UntypedFormControl([], [...(validators['fulfillmentOverdueSecondInventoryIds'] ?? []), ...[]]),
			'metabolicFormulaSubmitted': new UntypedFormControl(0, [...(validators['metabolicFormulaSubmitted'] ?? []), ...[Validators.required]]),
			'metabolicFormulaComments': new UntypedFormControl(0, [...(validators['metabolicFormulaComments'] ?? []), ...[Validators.required]]),
			'inventoryChange': new UntypedFormControl(0, [...(validators['inventoryChange'] ?? []), ...[Validators.required]]),
			'herosPendingAgencyEmails': new UntypedFormControl(0, [...(validators['herosPendingAgencyEmails'] ?? []), ...[Validators.required]]),
			'newCiInvoicesAvailable': new UntypedFormControl(0, [...(validators['newCiInvoicesAvailable'] ?? []), ...[Validators.required]]),
			'newCiInvoicesAvailableNotifications': new UntypedFormControl(0, [...(validators['newCiInvoicesAvailableNotifications'] ?? []), ...[Validators.required]]),
			'repackInvoiceEmail': new UntypedFormControl(0, [...(validators['repackInvoiceEmail'] ?? []), ...[Validators.required]]),
			'newCiOrdersAvailable': new UntypedFormControl(0, [...(validators['newCiOrdersAvailable'] ?? []), ...[Validators.required]]),
			'ciOrderCommentEmail': new UntypedFormControl(0, [...(validators['ciOrderCommentEmail'] ?? []), ...[Validators.required]]),
			'ciOrderNotSubmitted': new UntypedFormControl(0, [...(validators['ciOrderNotSubmitted'] ?? []), ...[Validators.required]]),
			'ciBatchControlRecord': new UntypedFormControl(0, [...(validators['ciBatchControlRecord'] ?? []), ...[Validators.required]]),
			'ciEndOfWeekMetrics': new UntypedFormControl(0, [...(validators['ciEndOfWeekMetrics'] ?? []), ...[Validators.required]]),
			'releaseNotes': new UntypedFormControl(0, [...(validators['releaseNotes'] ?? []), ...[Validators.required]]),
			'helpDeskTicketUpdate': new UntypedFormControl(false, [...(validators['helpDeskTicketUpdate'] ?? []), ...[]]),
			'userEmailBounced': new UntypedFormControl(0, [...(validators['userEmailBounced'] ?? []), ...[Validators.required]]),
			'newErrorReportSubmitted': new UntypedFormControl(0, [...(validators['newErrorReportSubmitted'] ?? []), ...[Validators.required]]),
			'baFiscalYearCreated': new UntypedFormControl(0, [...(validators['baFiscalYearCreated'] ?? []), ...[Validators.required]]),
			'baNewBudgetCreated': new UntypedFormControl(0, [...(validators['baNewBudgetCreated'] ?? []), ...[Validators.required]]),
			'budgetAllocationAdmin': new UntypedFormControl(0, [...(validators['budgetAllocationAdmin'] ?? []), ...[Validators.required]]),
			'organizationAccountRequestEmail': new UntypedFormControl(0, [...(validators['organizationAccountRequestEmail'] ?? []), ...[Validators.required]]),
			'organizationAccountExpirationEmail': new UntypedFormControl(0, [...(validators['organizationAccountExpirationEmail'] ?? []), ...[Validators.required]]),
			'ticketUpdate': new UntypedFormControl(0, [...(validators['ticketUpdate'] ?? []), ...[Validators.required]]),
			'ticketCreated': new UntypedFormControl(0, [...(validators['ticketCreated'] ?? []), ...[Validators.required]]),
			'ticketStatusChange': new UntypedFormControl(0, [...(validators['ticketStatusChange'] ?? []), ...[Validators.required]]),
			'ticketCommentAdded': new UntypedFormControl(0, [...(validators['ticketCommentAdded'] ?? []), ...[Validators.required]]),
			'ticketAssigned': new UntypedFormControl(0, [...(validators['ticketAssigned'] ?? []), ...[Validators.required]]),
			'newSurveyAvailable': new UntypedFormControl(0, [...(validators['newSurveyAvailable'] ?? []), ...[Validators.required]]),
			'surveyCompletionReminder': new UntypedFormControl(0, [...(validators['surveyCompletionReminder'] ?? []), ...[Validators.required]]),
			'overdueSurveyReminder': new UntypedFormControl(0, [...(validators['overdueSurveyReminder'] ?? []), ...[Validators.required]]),
			'scheduleSiteVisitReminder': new UntypedFormControl(0, [...(validators['scheduleSiteVisitReminder'] ?? []), ...[Validators.required]]),
			'postSiteVisitReminder': new UntypedFormControl(0, [...(validators['postSiteVisitReminder'] ?? []), ...[Validators.required]]),
			'sysInvOrderSubmitted': new UntypedFormControl(0, [...(validators['sysInvOrderSubmitted'] ?? []), ...[Validators.required]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): UserEmailSettingsVmFromFg {
		return new UserEmailSettingsVmFromFg(fg);
	}
}

export class UserEmailSettingsVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get userEmailSettingId(): number {
		return this.fg.get('userEmailSettingId').value;
	}
	set userEmailSettingId(val) {
		this.fg.get('userEmailSettingId').setValue(val);
	}

	get userId(): number {
		return this.fg.get('userId').value;
	}
	set userId(val) {
		this.fg.get('userId').setValue(val);
	}

	get reconciliationEmails(): number {
		return this.fg.get('reconciliationEmails').value;
	}
	set reconciliationEmails(val) {
		this.fg.get('reconciliationEmails').setValue(val);
	}

	get reconciliationNotifications(): number {
		return this.fg.get('reconciliationNotifications').value;
	}
	set reconciliationNotifications(val) {
		this.fg.get('reconciliationNotifications').setValue(val);
	}

	get reorderLowInventory(): number {
		return this.fg.get('reorderLowInventory').value;
	}
	set reorderLowInventory(val) {
		this.fg.get('reorderLowInventory').setValue(val);
	}

	get overrideReorderSettings(): number {
		return this.fg.get('overrideReorderSettings').value;
	}
	set overrideReorderSettings(val) {
		this.fg.get('overrideReorderSettings').setValue(val);
	}

	get fulfillmentOverdue1(): number {
		return this.fg.get('fulfillmentOverdue1').value;
	}
	set fulfillmentOverdue1(val) {
		this.fg.get('fulfillmentOverdue1').setValue(val);
	}

	get fulfillmentOverdue2(): number {
		return this.fg.get('fulfillmentOverdue2').value;
	}
	set fulfillmentOverdue2(val) {
		this.fg.get('fulfillmentOverdue2').setValue(val);
	}

	get fulfillmentUserStatusChange(): number {
		return this.fg.get('fulfillmentUserStatusChange').value;
	}
	set fulfillmentUserStatusChange(val) {
		this.fg.get('fulfillmentUserStatusChange').setValue(val);
	}

	get fulfillmentUserRxDeleted(): number {
		return this.fg.get('fulfillmentUserRxDeleted').value;
	}
	set fulfillmentUserRxDeleted(val) {
		this.fg.get('fulfillmentUserRxDeleted').setValue(val);
	}

	get metabolicFormulaSubmitted(): number {
		return this.fg.get('metabolicFormulaSubmitted').value;
	}
	set metabolicFormulaSubmitted(val) {
		this.fg.get('metabolicFormulaSubmitted').setValue(val);
	}

	get metabolicFormulaComments(): number {
		return this.fg.get('metabolicFormulaComments').value;
	}
	set metabolicFormulaComments(val) {
		this.fg.get('metabolicFormulaComments').setValue(val);
	}

	get inventoryChange(): number {
		return this.fg.get('inventoryChange').value;
	}
	set inventoryChange(val) {
		this.fg.get('inventoryChange').setValue(val);
	}

	get herosPendingAgencyEmails(): number {
		return this.fg.get('herosPendingAgencyEmails').value;
	}
	set herosPendingAgencyEmails(val) {
		this.fg.get('herosPendingAgencyEmails').setValue(val);
	}

	get newCiInvoicesAvailable(): number {
		return this.fg.get('newCiInvoicesAvailable').value;
	}
	set newCiInvoicesAvailable(val) {
		this.fg.get('newCiInvoicesAvailable').setValue(val);
	}

	get newCiInvoicesAvailableNotifications(): number {
		return this.fg.get('newCiInvoicesAvailableNotifications').value;
	}
	set newCiInvoicesAvailableNotifications(val) {
		this.fg.get('newCiInvoicesAvailableNotifications').setValue(val);
	}

	get repackInvoiceEmail(): number {
		return this.fg.get('repackInvoiceEmail').value;
	}
	set repackInvoiceEmail(val) {
		this.fg.get('repackInvoiceEmail').setValue(val);
	}

	get newCiOrdersAvailable(): number {
		return this.fg.get('newCiOrdersAvailable').value;
	}
	set newCiOrdersAvailable(val) {
		this.fg.get('newCiOrdersAvailable').setValue(val);
	}

	get ciOrderCommentEmail(): number {
		return this.fg.get('ciOrderCommentEmail').value;
	}
	set ciOrderCommentEmail(val) {
		this.fg.get('ciOrderCommentEmail').setValue(val);
	}

	get ciOrderNotSubmitted(): number {
		return this.fg.get('ciOrderNotSubmitted').value;
	}
	set ciOrderNotSubmitted(val) {
		this.fg.get('ciOrderNotSubmitted').setValue(val);
	}

	get ciBatchControlRecord(): number {
		return this.fg.get('ciBatchControlRecord').value;
	}
	set ciBatchControlRecord(val) {
		this.fg.get('ciBatchControlRecord').setValue(val);
	}

	get ciEndOfWeekMetrics(): number {
		return this.fg.get('ciEndOfWeekMetrics').value;
	}
	set ciEndOfWeekMetrics(val) {
		this.fg.get('ciEndOfWeekMetrics').setValue(val);
	}

	get releaseNotes(): number {
		return this.fg.get('releaseNotes').value;
	}
	set releaseNotes(val) {
		this.fg.get('releaseNotes').setValue(val);
	}

	get helpDeskTicketUpdate(): boolean {
		return this.fg.get('helpDeskTicketUpdate').value;
	}
	set helpDeskTicketUpdate(val) {
		this.fg.get('helpDeskTicketUpdate').setValue(val);
	}

	get userEmailBounced(): number {
		return this.fg.get('userEmailBounced').value;
	}
	set userEmailBounced(val) {
		this.fg.get('userEmailBounced').setValue(val);
	}

	get newErrorReportSubmitted(): number {
		return this.fg.get('newErrorReportSubmitted').value;
	}
	set newErrorReportSubmitted(val) {
		this.fg.get('newErrorReportSubmitted').setValue(val);
	}

	get baFiscalYearCreated(): number {
		return this.fg.get('baFiscalYearCreated').value;
	}
	set baFiscalYearCreated(val) {
		this.fg.get('baFiscalYearCreated').setValue(val);
	}

	get baNewBudgetCreated(): number {
		return this.fg.get('baNewBudgetCreated').value;
	}
	set baNewBudgetCreated(val) {
		this.fg.get('baNewBudgetCreated').setValue(val);
	}

	get budgetAllocationAdmin(): number {
		return this.fg.get('budgetAllocationAdmin').value;
	}
	set budgetAllocationAdmin(val) {
		this.fg.get('budgetAllocationAdmin').setValue(val);
	}

	get organizationAccountRequestEmail(): number {
		return this.fg.get('organizationAccountRequestEmail').value;
	}
	set organizationAccountRequestEmail(val) {
		this.fg.get('organizationAccountRequestEmail').setValue(val);
	}

	get organizationAccountExpirationEmail(): number {
		return this.fg.get('organizationAccountExpirationEmail').value;
	}
	set organizationAccountExpirationEmail(val) {
		this.fg.get('organizationAccountExpirationEmail').setValue(val);
	}

	get ticketUpdate(): number {
		return this.fg.get('ticketUpdate').value;
	}
	set ticketUpdate(val) {
		this.fg.get('ticketUpdate').setValue(val);
	}

	get ticketCreated(): number {
		return this.fg.get('ticketCreated').value;
	}
	set ticketCreated(val) {
		this.fg.get('ticketCreated').setValue(val);
	}

	get ticketStatusChange(): number {
		return this.fg.get('ticketStatusChange').value;
	}
	set ticketStatusChange(val) {
		this.fg.get('ticketStatusChange').setValue(val);
	}

	get ticketCommentAdded(): number {
		return this.fg.get('ticketCommentAdded').value;
	}
	set ticketCommentAdded(val) {
		this.fg.get('ticketCommentAdded').setValue(val);
	}

	get ticketAssigned(): number {
		return this.fg.get('ticketAssigned').value;
	}
	set ticketAssigned(val) {
		this.fg.get('ticketAssigned').setValue(val);
	}

	get newSurveyAvailable(): number {
		return this.fg.get('newSurveyAvailable').value;
	}
	set newSurveyAvailable(val) {
		this.fg.get('newSurveyAvailable').setValue(val);
	}

	get surveyCompletionReminder(): number {
		return this.fg.get('surveyCompletionReminder').value;
	}
	set surveyCompletionReminder(val) {
		this.fg.get('surveyCompletionReminder').setValue(val);
	}

	get overdueSurveyReminder(): number {
		return this.fg.get('overdueSurveyReminder').value;
	}
	set overdueSurveyReminder(val) {
		this.fg.get('overdueSurveyReminder').setValue(val);
	}

	get scheduleSiteVisitReminder(): number {
		return this.fg.get('scheduleSiteVisitReminder').value;
	}
	set scheduleSiteVisitReminder(val) {
		this.fg.get('scheduleSiteVisitReminder').setValue(val);
	}

	get postSiteVisitReminder(): number {
		return this.fg.get('postSiteVisitReminder').value;
	}
	set postSiteVisitReminder(val) {
		this.fg.get('postSiteVisitReminder').setValue(val);
	}

	get sysInvOrderSubmitted(): number {
		return this.fg.get('sysInvOrderSubmitted').value;
	}
	set sysInvOrderSubmitted(val) {
		this.fg.get('sysInvOrderSubmitted').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}
}
