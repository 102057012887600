<form 
    id="moduleAccessForm" 
    *ngIf="editUserService.moduleAccessesSignal()" 
    [formGroup]="formGroup" 
    pcgSubmitUrl="api/Administration/ModuleAccess/SaveModule/" 
    (messageSuccess)="updateForm($event.model)"
>
    <div id="informativeContent">
        <p>This page details a user's access to the HOMER system by module and role.</p>

        <div 
            *ngIf="isSysAdmin" 
            class="modal-body" 
            id="HelpfulTip"
        >
            <p>
                <fa-icon class="lightbulb yellow-lightbulb"></fa-icon>
                <span class="pl-2">
                    Tip: To view a breakdown of module access by role, select the button containing the modules name. 
                    To remove access to a module for a user, select "No Access" from the dropdown.
                </span>
            </p>
        </div>
        <div *ngIf="!isSysAdmin">
            <input 
                type="button" 
                class="btn btn-save module-button py-2" 
                (click)="createTicket()"
                [value]="'Request Higher Permissions'" 
            />
        </div>
    </div>
    <hr />

    @for(module of editUserService.moduleAccessesSignal().moduleAccess; track module.moduleAccessId) {
        <div  class="module-row">
            <div class="button-and-select">
                <div>
                    <input 
                        type="button" 
                        class="btn btn-lg {{(module.permissionRole == 0 ? 'btn-delete' : 'btn-save')}} module-button py-2" 
                        (click)="openRoleInfoModal(module.module)"
                        [value]="getDisplayName(module.module)" 
                    />
                </div>

                <div>
                    <pcg-select
                        pcgControlRef 
                        ngDefaultControl 
                        dataSource="api/Select/PermissionRoles?module={{module.module | number}}" 
                        [sendFormData]="false" 
                        (change)="updateModuleAccess()"
                        [(ngModel)]="module.permissionRole" 
                        [ngModelOptions]="{ standalone: true }" 
                        [disabled]="!canEditModuleAccess(module.module, module.permissionRole)" 
                        [class.text-danger]="module.permissionRole == 0"
                    ></pcg-select>
                </div>
            </div>
            @if (module.updatedByUser != null) {
                <div>
                    <p>
                        Last changed on 
                        <span ngbTooltip="{{module.updateByDate | date:'long' }}">
                            {{ module.updateByDate | date }}
                        </span> 
                        by {{ module.updatedByUser }}
                    </p>
                </div>
            }
        </div>
    }
</form>