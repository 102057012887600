<form 
    (ngSubmit)="checkCredential()" 
    [formGroup]="pinForm"
>
	<div class="modal-header">
		<h5 
            class="modal-title" 
            id="modal-basic-title"
        >Register PIN</h5>
        @if (allowDismiss) {
            <button 
                type="button" 
                class="close" 
                aria-label="Close" 
                (click)="dismiss.emit()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        }
	</div>

	<div class="modal-body">
        @if (needToChangePin) { <div class="alert alert-warning text-center">You must change your pin due to new security requirements.</div> }
		<p>
            Please enter your preferred PIN.
            <br />
            Pins cannot contain repetitive numbers more than twice.<br />(ex. 1111)
            <br />
            Pins cannot contain sequential numbers more than twice.<br />(ex. 1234 or 4321)
        </p>
		<div class="row">
			<pcg-control-group class="col-sm-12 pin">
				<pcg-show-hide-pin 
                    id="pin" 
                    fcName="pin" 
                    [parentForm]="pinForm" 
                    label="PIN (4-10 digits)"
					[validations]="{ pattern: 'PIN must include only numbers.' }" 
                    type="password"
                ></pcg-show-hide-pin>
			</pcg-control-group>
		</div>

		<div class="row">
			<pcg-control-group class="col-sm-12">
				<pcg-show-hide-pin 
                    id="pin" 
                    fcName="confirmPin" 
                    [parentForm]="pinForm" 
                    label="Confirm PIN"
					[validations]="{ pinsNotEqual: 'Pins do not match.' }" 
                    type="password"
                ></pcg-show-hide-pin>
			</pcg-control-group>
		</div>

        @if (hasError) { <div class="alert alert-danger text-center">{{ errorStr }}</div> }
	</div>

	<div class="modal-footer">
        @if (allowDismiss) {
            <button 
                class="btn btn-cancel btn-lg" 
                (click)="dismiss.emit()"
            >Cancel</button>
        }
		<button 
            type="submit" 
            class="btn btn-save btn-lg"
        >Register</button>
	</div>
</form>