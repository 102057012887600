import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { RegisterPinForm } from '../register-pin-modal/register-pin.form';

@Component({
    selector: 'pcg-register-pin-modal',
    templateUrl: './register-pin-modal.component.html',
    styleUrls: ['./register-pin-modal.component.scss'],
    standalone: false
})
export class RegisterPinModalComponent implements OnInit {

    @Output() emitPin: EventEmitter<string> = new EventEmitter();
	@Output() dismiss: EventEmitter<void> = new EventEmitter();

    pinForm: UntypedFormGroup = null;
	id: number;
    needToChangePin = false;
    allowDismiss = true;
    hasError = false;
    errorStr = "";

    constructor(
		httpClient: HttpClient
		, private ms: SystemMessageService
		, private route: ActivatedRoute
	) { 
        const pinForm = new RegisterPinForm(httpClient);
		this.pinForm = pinForm.formGroup;
    }

    ngOnInit() {
        if (this.needToChangePin === true) { this.allowDismiss = false; }
        else { this.route.paramMap.subscribe(map => { this.id = parseInt(map.get('id'), 10); }); }
		document.getElementById('pin').focus();
    }

    checkCredential() {
        this.hasError = false;
        this.errorStr = "";

		if (this.pinForm.valid) {
			this.pinForm.addControl('userId', new UntypedFormControl('', Validators.required));
			this.pinForm.patchValue({ userId: this.id });
            this.ms.getHttpObservable(this, 'api/Administration/Users/SavePIN', this.pinForm)
                .subscribe((msg: SystemMessage) => {
                    if (msg.isSuccessful) {
                        this.ms.setSystemMessage(msg.message);
                        this.emitPin.emit(this.pinForm.controls['pin'].value as string);
                        this.dismiss.emit();
                        if (this.needToChangePin === true) { 
                            window.location.href = document.getElementsByTagName('base')[0].href 
                                + `admin/users/user/edit/${this.id}?tab=Security`;
                        }
                    } else { 
                        this.hasError = true;
                        this.errorStr = msg.message;
                    }
                }
			);
		} else { validateForm(this.pinForm); }
	}
}
